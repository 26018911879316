import React from "react"
import SEO from "../components/seo"
import PageContainer from "../components/pageContainer"
import CaseStudiesSingleTitle from "../../src/components/caseStudiesSingle/caseStudiesSingleTitle"
import CaseStudiesExtendedContent from "../../src/components/caseStudiesSingle/caseStudiesExtendedContent"
import Header from "../components/header/header"
import { graphql, Link } from "gatsby"
import BackButton from "../components/common/buttons/backButton"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { LocalizationContext } from "../layout/layout"
import { saveUrlsToLocalStorage, urlToPage } from "../components/common/type-to-url-match/type-to-url-match"

class ApplicationSinglePage extends React.Component {

    componentDidMount() {
        const { dispatch, pageContext } = this.props
        const entries = (this.props.data.entries && this.props.data.entries[0]) || ""
        const topColors = entries.archeeTopKolory
        dispatch(setTopColors(topColors))
        dispatch(toggleSubmenu(false))
        saveUrlsToLocalStorage(pageContext)
    }

    render() {
        const { data, localize } = this.props
        const entries = localize.lang === "pl" ? data.pl.nodes?.[0] || "" : data.en.nodes?.[0]  || "";
        const extendedContent = entries.archeeTresc;
        // TODO: do poprawy
        const typeName = 'Craft_archeeAplikacje_archeeAplikacje_Entry';
        const url = urlToPage(localize.lang)[typeName]
        return (
            <>
                <Header/>
                <PageContainer>
                    <BackButton as={Link} to={url}/>
                    {/*<CaseStudiesSingleTitle data={entries}/>*/}
                    <CaseStudiesExtendedContent data={extendedContent}/>
                </PageContainer>
            </>
        )
    }
}

const LocalizedCaseStudiesSingleSeeAlso = React.forwardRef((props, ref) => (
    <LocalizationContext.Consumer>
        {localize => <ApplicationSinglePage {...props} localize={localize} ref={ref}/>}
    </LocalizationContext.Consumer>
))

export default connect(state => ({
    topColors: state.app.topColors,
    isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(LocalizedCaseStudiesSingleSeeAlso)

export const query = graphql`
    query($id: String) {
        seo: allCraftArcheeAplikacjaArcheeAplikacjaEntry(filter: {id: {eq: $id}, language: {eq: "pl"}}) {
            nodes{
                ...seoApplicationSingle
            }
        }
        seoEn: allCraftArcheeAplikacjaArcheeAplikacjaEntry(filter: {id: {eq: $id}, language: {eq: "en"}}) {
            nodes{
                ...seoApplicationSingle
            }
        }
        pl: allCraftArcheeAplikacjaArcheeAplikacjaEntry(filter: {id: {eq: $id}, language: {eq: "pl"}}){
            __typename
            nodes{
                ...applicationPost
            }
        }
        en: allCraftArcheeAplikacjaArcheeAplikacjaEntry(filter: {id: {eq: $id}, language: {eq: "en"}}) {
            __typename
            nodes{
                ...applicationPost
            }
        }
    }
`

export function Head({ data, pageContext }) {
    const seoData = pageContext.langKey === 'pl' ? data?.seo?.nodes?.[0] || [] : data?.seoEn?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}
